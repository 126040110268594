import { Component, HostListener, Inject, Input, PLATFORM_ID, ViewChild, afterNextRender } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { CartService } from '@/app/shared/services/cart.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-cart',
  templateUrl: './quick-cart.component.html',
  styleUrls: ['./quick-cart.component.scss'],
})
export class QuickCartComponent {
  public cart: Cart = new Cart();

  @Input() sidebarCartOpen: boolean = false;

  // @ViewChild("variationModal") VariationModal: VariationModalComponent;

  @Input() style: string = 'basic';
  public isAccountActivated: boolean = false;
  public cartStyle: string = 'cart_sidebar';
  public shippingFreeAmt: number = 1000000;
  public cartTotal: number = 0;
  public shippingCal: number = 0;
  public confettiItems = Array.from({ length: 150 }, (_, index) => index);
  public confetti: number = 0;
  public loader: boolean = false;
  public sticky: boolean = false;
  public totalQuantity: number = 0;
  selected: number = 0;
  isAllCartItemsSelected: boolean = false;
  selectedItems: CartItem[] = [];

  constructor(
    private commonService: CommonService,
    private cartService: CartService,
    private toastService: ToastrService,
    private router: Router,
    private accountService: AccountService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
  ) {
    afterNextRender(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      let number = window.screenY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number >= 130 && window.innerWidth > 400) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }
  }

  ngOnInit() {
    this.isAccountActivated = this.authService.isAccountActivated();
    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          this.cartTotal = this.cart.sumTotalPrice;
          this.totalQuantity = this.cart.items.reduce((current, next) => { return current + next.quantity }, 0);
          this.selected = this.cart.items.filter((item) => item.isSelected).length;
        }
      },
    });
  }

  cartToggle() {
    this.router.navigateByUrl("/cart");
  }

  deleteCartItem(product: any) {
    let body = {
      productId: product.productID,
      quantity: 0,
    };
    this.cartService.updateCart(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        product.quantity = body.quantity;
        this.commonService.setCart(res);
        this.toastService.success('Đã cập nhật giỏ hàng!', "", { positionClass: "toast-top-right" });
      },
      error: (error) => {
        product.quantity = 0;
        console.log(error);
        this.toastService.warning('Lỗi cập nhật giỏ hàng!', "", { positionClass: "toast-top-right" });
      },
    });
  }

  selectCheckbox(cartItem: CartItem) {
    $('.chk-all').prop('checked', $('.chk').length == $('.chk:checked').length);
    this.selected = $('.chk:checked').length;
    if (
      !this.selectedItems.some((item) => item.productID == cartItem.productID)
    ) {
      this.selectedItems.push(cartItem);
    } else {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.productID != cartItem.productID
      );
    }

    let body = {
      productId: cartItem.productID,
      quantity: cartItem.quantity,
      isSelected: !cartItem.isSelected,
    };
    this.cartService.updateCart(JSON.stringify(body)).subscribe(
      (res: any) => {
        this.cart = res;
        this.commonService.setCart(this.cart);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkAll() {
    this.isAllCartItemsSelected = !this.isAllCartItemsSelected;
    $('.chk').prop('checked', $('.chk-all').prop('checked'));
    this.selected = $('.chk:checked').length;
    if ($('.chk').prop('checked')) {
      this.selectedItems = this.cart.items;
    } else {
      this.selectedItems = [];
    }

    if (this.isAllCartItemsSelected) {
      this.cart.items.forEach((item) => {
          item.isSelected = true;
      });
    }
    else {
      this.cart.items.forEach((item) => {
        item.isSelected = false;
      });
    }
    this.updateMany(this.cart.items);
  }

  updateMany(list: CartItem[]) {
    let items: any[] = [];
    for (const cartItem of list) {
      const item = {
        productId: cartItem.productID,
        quantity: cartItem.quantity,
        isSelected: cartItem.isSelected,
      };
      items.push(item);
    }
    const body = {
      items: items,
    };
    this.cartService.updateCartMany(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        this.cart = res;
        this.commonService.setCart(this.cart);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
